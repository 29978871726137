import React, { useState, useEffect, useRef } from 'react';
import { IoIosSend } from 'react-icons/io';
import interview_chat from '../../Assets/interview_chat.png';
import { useSelector } from 'react-redux';
import { postRequest } from '../../Util/apiHelper';
import { HiMicrophone } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import SkeletonLoader from '../../components/misc/SkeletonLoader';
import TextareaAutosize from 'react-textarea-autosize';
import DOMPurify from 'dompurify';
import {
  fetchChatsForInterview,
  endInterview,
  getMessagesForBatching,
} from './ChatHelpers';
import {
  base64ToArrayBuffer,
  handleStopRecording,
  handleStartRecording,
} from './SpeechToTextHelper';

const Chat = React.memo((props) => {
  const interviewId = props.id;
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [inputvalue, setInputvalue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatsLoaded, setChatsLoaded] = useState(false);
  const [messages, setMessages] = useState(
    props.firstQuestion !== null ? [props.firstQuestion] : []
  );
  const [systemMessage, setSystemMessage] = useState('');
  const user = useSelector((state) => state.user.user);
  const audioSourceRef = useRef(null);
  const audioContextRef = useRef(null);
  const chatContainerRef = useRef(null);
  const realtimeTranscriber = useRef(null);
  const recorder = useRef(null);
  const assemblyAITokenRef = useRef(null);
  const audioStreamRef = useRef(null);
  const currentAudioBuffer = useRef(null);
  const nextAudioBatch = useRef(null);
  const audioDurationRef = useRef(0);
  const isNextBatchLoading = useRef(false);
  const messagesForBatching = useRef([]);
  const ttsBatchingSetIntervalRef = useRef(null);

  useEffect(() => {
    if (messages.length === 0) {
      if (!chatsLoaded) {
        fetchChatsForInterview(
          setMessages,
          user.token,
          interviewId,
          setChatsLoaded
        );
      }
    } else {
    }
  }, [interviewId, user.token, messages, chatsLoaded]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    if (
      messages.length > 0 &&
      messages[messages.length - 1].role === 'system'
    ) {
      const lastSystemMessage = messages[messages.length - 1].content;
      const words = lastSystemMessage.split(' ');
      let currentWordIndex = 0;
      const updateMessage = () => {
        if (currentWordIndex === 0) {
          setSystemMessage(words[currentWordIndex]);
        }
        if (currentWordIndex < words.length) {
          setSystemMessage(
            (prevMessage) => prevMessage + ' ' + words[currentWordIndex]
          );
          currentWordIndex++;
          if (currentWordIndex === words.length - 1) {
            clearInterval(intervalId);
          }
        }
      };
      updateMessage();
      const intervalId = setInterval(updateMessage, 100);
      return () => clearInterval(intervalId);
    } else {
      setSystemMessage('');
    }
  }, [messages]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage.role === 'system') {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }
  }, [messages, systemMessage]);

  const handleSend = async () => {
    if (isRecording) {
      await handleStopRecording(realtimeTranscriber, recorder, setIsRecording);
    }
    const sanitizedInput = DOMPurify.sanitize(inputvalue);
    setMessages([...messages, { content: sanitizedInput, role: user }]);
    setInputvalue('');
    try {
      setIsLoading(true);
      const response = await postRequest(
        `api/v1/interview/interact`,
        {
          message: sanitizedInput,
          interviewId: interviewId,
        },
        user.token
      );
      setIsLoading(false);
      if (!response.error) {
        const sanitizedResponseText = DOMPurify.sanitize(response.data.text);
        const tempMessagesForBatching = getMessagesForBatching(
          sanitizedResponseText
        );
        messagesForBatching.current = tempMessagesForBatching;
        setMessages((prevMessages) => [
          ...prevMessages,
          { content: sanitizedResponseText, role: 'system' },
        ]);
        await speakAudio(response.data.audio, audioContextRef, audioSourceRef);
      } else {
        console.error('Error in response:', response.message);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const speakAudio = async (audio) => {
    const arrayBuffer = base64ToArrayBuffer(audio);
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }
    const audioContext = audioContextRef.current;
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    if (audioSourceRef.current) {
      audioSourceRef.current.stop();
    }

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    currentAudioBuffer.current = audioBuffer;
    audioDurationRef.current = audioBuffer.duration;
    source.start(0);

    source.startTime = audioContext.currentTime; // Track when the audio starts
    audioSourceRef.current = source;
    // Start tracking progress for batching
    trackAudioProgress(source, audioBuffer.duration, audioContextRef);
  };

  const trackAudioProgress = (source, duration, audioContextRef) => {
    if (ttsBatchingSetIntervalRef.current) {
      console.log('KANAV: stopping the previously running batching');
      clearInterval(ttsBatchingSetIntervalRef.current);
    }
    ttsBatchingSetIntervalRef.current = setInterval(() => {
      const elapsedTime =
        audioContextRef.current.currentTime - source.startTime;
      const progress = elapsedTime / duration;
      if (progress >= 0.5 && nextAudioBatch.current === null) {
        console.log('Progress > 0.5 Loading next batch');
        loadNextBatch();
      }

      if (progress >= 1) {
        clearInterval(ttsBatchingSetIntervalRef.current);
        console.log('KANAV: Playing next batch. Previous is complete');
        playNextAudioBatch();
      }
    }, 1000);
  };

  const playNextAudioBatch = async () => {
    if (nextAudioBatch.current) {
      const temp = nextAudioBatch.current;
      nextAudioBatch.current = null;
      await speakAudio(temp);
    } else {
      console.log('KANAV: nextAudioBatch.current is null :/');
    }
  };

  const loadNextBatch = async () => {
    if (
      !isNextBatchLoading.current &&
      messagesForBatching.current &&
      messagesForBatching.current.length > 0
    ) {
      isNextBatchLoading.current = !isNextBatchLoading.current;
      try {
        console.log(
          '\n\nKANAV: value of messagesForBatching.current is ',
          messagesForBatching.current
        );
        let nextBatchText = messagesForBatching.current[0];
        if (messagesForBatching.current.length > 1) {
          nextBatchText = nextBatchText + '.' + messagesForBatching.current[1];
          messagesForBatching.current = messagesForBatching.current.slice(2);
        } else {
          messagesForBatching.current = messagesForBatching.current.slice(1);
        }

        console.log('KANAV: loading next text: ', nextBatchText);

        const response = await postRequest(
          'api/v1/audio/texttospeech',
          {
            text: nextBatchText,
          },
          user.token
        );

        if (!response.error) {
          console.log(
            'Setting next audio batch, nextAudioBatch.current = response.data.audio;'
          );
          nextAudioBatch.current = response.data.audioBuffer;
        }
      } finally {
        isNextBatchLoading.current = !isNextBatchLoading.current;
      }
    } else {
      console.log('KANAV: next batch is already loading. Skipping this');
    }
  };

  const handleToggleRecording = async (token) => {
    if (isRecording) {
      handleStopRecording(realtimeTranscriber, recorder, setIsRecording);
    } else {
      handleStartRecording(
        token,
        assemblyAITokenRef,
        audioStreamRef,
        realtimeTranscriber,
        setInputvalue,
        inputvalue,
        recorder,
        setIsRecording
      );
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop } = chatContainerRef.current;

      if (scrollTop === 0 && !loading) {
        setLoading(true);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="flex flex-col h-[85vh] p-5 pt-10">
      <div
        ref={chatContainerRef}
        onScroll={handleScroll}
        className="flex-1 overflow-y-auto text-sm m-20 my-0"
      >
        {messages.map((message, index) => (
          <div key={index} role={message.role}>
            <div
              className={`flex text-sm ${message.role === 'system' ? 'justify-start' : 'justify-end'}`}
            >
              <div
                className={`flex items-center ${message.role === 'system' ? 'w-2/3 p-2' : 'w-2/3 justify-end p-2'}`}
              >
                {message.role === 'system' && (
                  <img
                    src={interview_chat}
                    className="h-[27px] mr-2"
                    alt="Chat icon"
                  />
                )}
                <div
                  className={`${message.role === 'system' ? 'p-3' : 'p-3 rounded-3xl bg-gray-200'}`}
                  style={{ maxWidth: '100%' }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        message.role === 'system' &&
                        index === messages.length - 1
                          ? systemMessage
                          : message.content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        {isLoading && <SkeletonLoader />}
      </div>

      <div className="border-gray-300 p-2 flex items-center space-x-4">
        <button
          className="bg-gray-200 p-4 rounded-lg text-black"
          onClick={() => {
            endInterview(interviewId, user, audioSourceRef, navigate);
          }}
        >
          End
        </button>
        <div className="relative flex-1">
          <TextareaAutosize
            value={inputvalue}
            minRows={1}
            maxRows={7}
            disabled={isLoading}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInputvalue(e.target.value)}
            placeholder="Message Truly Interviewed"
            className="pr-16 py-5 pl-4 bg-gray-100 text-sm w-full rounded-3xl outline-none"
          />
          <IoIosSend
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-600 cursor-pointer text-2xl"
            onClick={handleSend}
          />
          <div
            className="absolute right-12 top-1/2 transform -translate-y-1/2 flex items-center space-x-2"
            onClick={async () => {
              await handleToggleRecording(user.token);
            }}
          >
            {isRecording ? (
              <HiMicrophone className="text-[#5b5bf7] cursor-pointer text-2xl" />
            ) : (
              <HiMicrophone className="text-gray-600 cursor-pointer text-2xl" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Chat;
